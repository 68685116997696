import { createApp } from "vue";
import App from "./App.vue";
import Combiner from "./components/Combiner";
import About from "./components/about";
import { createRouter, createWebHistory } from "vue-router";

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  // history: createWebHistory(),
  routes: [
    {
      path: "/about",
      component: About
    },
    {
      path: "/",
      component: Combiner
    }
  ]
});
const app = createApp(App);
app.use(router);
app.mount("#app");
// createApp(App).mount('#app')
