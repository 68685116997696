<template>
  <div id="app">
    <!-- <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About</router-link>
        </li>
      </ul>
    </nav> -->
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    emojiToImg(emoji) {
      let hex = emoji.codePointAt(0).toString(16);
      return (
        "https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u" +
        hex +
        ".svg"
      );
    },
  },
  mounted() {
    // Example usage.
    //console.log(getEmojiMixUrl("🔥", "😃"));
  },
};
</script>

<style>
body {
  /* background-image: url(http://bradjasper.com/subtle-patterns-bookmarklet/patterns/daimond_eyes.png); */
  background-repeat: repeat;
  background-size: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  /* margin-top: 20px; */
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* flex-direction: column; */
}
.router-link-active {
  border-bottom: 1px solid #42b983;
}
footer {
  position: sticky;
  top: 99%;
  bottom: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}
button {
  background: transparent;
  outline: none;
}
</style>
