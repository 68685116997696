<template>
  <h6>About</h6>
</template>

<script>
export default {
  name: "goodbye",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>